// Path: ./src/app/store/state.ts
// reference: https://github.com/hellomuthu23/react-context-example
import { Position } from '@capacitor/geolocation';
import {
  GfCustomerDto,
  GfFlyerDto,
  GfPromotionDto,
  GfStoreDto,
} from '@swagger/typescript-fetch-goflyer';

export interface MapAllStores extends GfStoreDto {
  promotions?: GfPromotionDto[];
}

export interface GfCustomerDtoWithLogin extends GfCustomerDto {
  isLogin: boolean;
  access_token: string;
}
export interface GoFlyerAppState {
  showBanner: boolean;
  location: Position;
  customer: GfCustomerDtoWithLogin;
  exploreFlyerAndPromotionList: (GfFlyerDto | GfPromotionDto)[] | undefined;
  bestDealsFlyerAndPromotionList: GfPromotionDto[] | undefined;
  exploreFlyerAndPromotionListNumber: number;
  bestDealListNumber: number;
  mapStoreAllStores: MapAllStores[] | undefined;
  mapStoreCounter: number;
  exploreScrollPosition: number;
  bestDealsScrollPosition: number;
  restaurantFlyersList: GfFlyerDto[] | undefined;
  restaurantFlyersListNumber: number;
  restaurantScrollPosition: number;
  flyerTagList: {
    restaurant: {
      flyer: GfFlyerDto[];
      position: number;
      number: number;
    };
    grocery: {
      flyer: GfFlyerDto[];
      position: number;
      number: number;
    };
    chinesesupermarket: {
      flyer: GfFlyerDto[];
      position: number;
      number: number;
    };
    electricalAppliances: {
      flyer: GfFlyerDto[];
      position: number;
      number: number;
    };
  };

  remoteConfig: {
    getSmallImagesCondition: boolean | string;
  };
  menuSideBar: boolean;
  latestFlyer: GfFlyerDto[];
  latestFlyerNumber: number;
  latestFlyerScrollPosition: number;
  upComingFlyer: GfFlyerDto[];
  upComingFlyerNumber: number;
  upComingFlyerScrollPosition: number;
  favoriteFlyer: {
    flyerList: GfStoreDto[];
    position: number;
  };
  searchScrollPosition: number;
  searchDataList: (GfFlyerDto | GfPromotionDto)[] | undefined;
  searchDataNumber: number;

  // promotionFavouriteList: GfPromotionFavouriteListDto;
  // storeFavouriteList: GfStoreFavouriteListDto;
}

export const initialGoFlyerState: GoFlyerAppState = {
  showBanner: true,
  location: {} as GeolocationPosition,
  customer: { isLogin: false, access_token: '' } as GfCustomerDtoWithLogin,
  exploreFlyerAndPromotionList: [] as
    | (GfFlyerDto | GfPromotionDto)[]
    | undefined,
  bestDealsFlyerAndPromotionList: [] as GfPromotionDto[] | undefined,
  exploreFlyerAndPromotionListNumber: 0,
  bestDealListNumber: 0,
  mapStoreAllStores: [] as MapAllStores[] | undefined,
  mapStoreCounter: 0,
  exploreScrollPosition: 0,
  bestDealsScrollPosition: 0,
  restaurantFlyersList: [] as GfFlyerDto[] | undefined,
  restaurantFlyersListNumber: 0,
  restaurantScrollPosition: 0,
  flyerTagList: {
    grocery: {
      flyer: [] as GfFlyerDto[],
      position: 0,
      number: 1,
    },
    restaurant: {
      flyer: [] as GfFlyerDto[],
      position: 0,
      number: 1,
    },
    chinesesupermarket: {
      flyer: [] as GfFlyerDto[],
      position: 0,
      number: 1,
    },
    electricalAppliances: {
      flyer: [] as GfFlyerDto[],
      position: 0,
      number: 1,
    },
  },

  remoteConfig: {
    getSmallImagesCondition: false,
  },
  menuSideBar: false,
  latestFlyer: [],
  latestFlyerNumber: 0,
  latestFlyerScrollPosition: 0,
  upComingFlyer: [],
  upComingFlyerNumber: 0,
  upComingFlyerScrollPosition: 0,
  favoriteFlyer: {
    flyerList: [] as GfStoreDto[],
    position: 0,
  },
  searchScrollPosition: 0,
  searchDataList: [] as (GfFlyerDto | GfPromotionDto)[] | undefined,
  searchDataNumber: 0,
  // promotionFavouriteList: {} as GfPromotionFavouriteListDto,
  // storeFavouriteList: {} as GfStoreFavouriteListDto,
};
