// Path: ./src/app/pages/InfoPage/index.tsx
/**
 *
 * InfoPage
 *
 */
import { TobNavBar } from './TopNav';
import * as React from 'react';
import styled from 'styled-components/macro';
import { TopInfoContent } from './TopContent';
import { FiftInfoContent } from './FifthContent';
import { BottomInfoContent } from './BottomContent';
import { FooterInfo } from './Footer';
import SideImage from '../../assets/SiteMain1.png';
import SideImage2 from '../../assets/FirstInfoimage1.png';
import SideImage3 from '../../assets/SecondInfoImage.webp';
import SideImage4 from '../../assets/ThirdInfoImage.webp';
import SideImage5 from '../../assets/FourthInfoImage1.png';

import { CommonInfoContent } from './CommanContent';
import { useTranslation } from 'react-i18next';
import { messages } from 'locales/messages';
import { localstorageGet } from 'utils/localstorage';

interface Props {}

export function InfoPage(props: Props) {
  const { t } = useTranslation();
  const Lang = localstorageGet('Lang') as string;

  const getVideo = () => {
    if (Lang === 'en') {
      return 'https://d280crdu6dot2n.cloudfront.net/website_assets/1493_1718922494_goflyer_info_page_English.mp4';
    } else if (Lang === 'zh') {
      return 'https://d280crdu6dot2n.cloudfront.net/website_assets/1494_1718922498_goflyer_info_page_Chinese.mp4';
    }
  };

  const InfoList = [
    {
      Id: 'TopInfoContent',
      right: false,
      bgColor: '#f3ece2',
      title: t(messages.Info_TopContent_Title()),
      subtitle: t(messages.Info_TopContent_Subtitle()),
      image: SideImage,
      video: getVideo(),
      buttonText: t(messages.Start_Saving_Today()),
      Link: '/flyers',
    },
    {
      Id: 'SecondInfoContent',
      right: true,
      bgColor: '#FFFFFF',
      title: t(messages.Info_ContentOne_Title()),
      subtitle: t(messages.Info_ContentOne_Subtitle()),
      image: SideImage2,
      video: `https://goflyer-app-image.s3.ca-central-1.amazonaws.com/images/File2.mp4`,
      buttonText: t(messages.Check_Out_The_latest_Flyers()),
      Link: '/flyers',
    },
    {
      Id: 'ThirdInfoContent',
      right: false,
      bgColor: '#f3ece2',
      title: t(messages.Info_ContentOne_Title()),
      subtitle: t(messages.Info_ContentOne_Subtitle()),
      image: SideImage3,
      buttonText: t(messages.Browse_Exclusive_Deals()),
      Link: '/promotionlist',
    },
    {
      Id: 'FourthInfoContent',
      right: true,
      bgColor: '#FFFFFF ',
      title: t(messages.Info_ContentThree_Title()),
      subtitle: t(messages.Info_ContentThree_Subtitle()),
      image: SideImage4,
      buttonText: t(messages.See_Available_Stores()),
      Link: '/flyers',
    },
    {
      Id: 'FifthInfoContent',
      right: false,
      bgColor: '#f3ece2',
      title: t(messages.Info_ContentFourth_Title()),
      subtitle: t(messages.Info_ContentFourth_Subtitle()),
      image: SideImage5,
      video:
        'https://goflyer-app-image.s3.ca-central-1.amazonaws.com/images/file.mp4',
      buttonText: t(messages.Start_Browsing_Deals()),
      Link: '/promotionlist',
    },
  ];
  return (
    <Div data-testid="infopage-test-id">
      <TobNavBar />
      {InfoList.map(value => {
        if (value.Id === 'TopInfoContent') {
          return (
            <TopInfoContent
              Id={value.Id}
              key={value.Id}
              title={value.title}
              subtitle={value.subtitle}
              image={value.image}
              video={value.video}
              buttonText={value.buttonText}
              backgroudColor={value.bgColor}
              Link={value.Link}
              data-testid="infopage-test-id"
            />
          );
        } else {
          return (
            <CommonInfoContent
              Id={value.Id}
              key={value.Id}
              title={value.title}
              subtitle={value.subtitle}
              image={value.image}
              buttonText={value.buttonText}
              backgroudColor={value.bgColor}
              rightSide={value.right}
              Link={value.Link}
              video={value.video}
              data-testid="infopage-test-id"
            />
          );
        }
      })}
      <FiftInfoContent />
      <BottomInfoContent />
      <FooterInfo />
    </Div>
  );
}

const Div = styled.div`
  overflow-x: hidden;
`;
