// Path: ./src/app/pages/SearchPage/index.tsx
/**
 *
 * SearchPage
 *
 */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Box } from '@mui/material';
import { apiService } from '../../../utils/api';
import {
  GfFlyerDto,
  GfPromotionDto,
  GfStoreDto,
} from '@swagger/typescript-fetch-goflyer';
import {
  checkIfPromotionInShoppingList,
  checkIfStoreInFavList,
  getFlyerStatusString,
  getDaysLeftOfPromotion,
  getMyDistanceToStore,
  getSmallImageIfExist,
  instanceOfFlyer,
  instanceOfPromotion,
} from 'utils/dtoUtility';
import { Spinner } from 'app/components/Spinner';
import InfiniteScroll from 'react-infinite-scroller';

import { GoFlyerAppContext } from 'app/store/context';
import { Link, useHistory, useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import { SearchInputField } from 'app/components/SearchInputField/SearchInputField';
import styled from 'styled-components/macro';
import { SearchHistory } from 'app/components/SearchHistory';
import {
  setCustomer,
  setSearchDataList,
  setSearchDataNumber,
  setSearchScrollPosition,
} from 'app/store/reducer';
import { localstorageGet, localstorageSet } from 'utils/localstorage';
import { CardFlyerNew } from 'app/components/CardFlyerNew';
import { ExclusiveDealCard } from '../../mobile-desktop-common-components/ExclusiveDealCard';
import { BestDealCard } from 'app/components/BestDealCard';
import { NoSearchResult } from 'app/components/NoSearchResult';
import { SearchSuggest } from 'app/components/SearchSuggest/Loadable';
import { messages } from 'locales/messages';
import { useTranslation } from 'react-i18next';
import * as analytics from '../../../utils/analytics';
import { useClickFavMobileFunc } from 'utils/commonFunctions/ClickFavButtonMobile';
import { useAddRemoveShoppingListMobileFunc } from 'utils/commonFunctions/AddRemoveShoppingListMobile';
import { calculateDistance } from 'utils/shortestDistance';

interface Props {}
interface TrendingSearch {
  id: string;
  text: string;
  count: number;
}
export interface SearchPageParams {
  term: string;
}
export const SearchPage: React.FunctionComponent<Props> = () => {
  const { term } = useParams<SearchPageParams>();
  const history = useHistory();
  const [pageLoader, setPageLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(true);
  const { state, dispatch } = useContext(GoFlyerAppContext);
  const [localSearch, setLocalSearch] = useState<[]>([]);
  const [trendingSearch, setTrendingSearch] = useState<TrendingSearch[] | []>(
    [],
  );
  const [cities, setCities] = useState<TrendingSearch[] | []>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [noSearchFound, setNoSearchFound] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    analytics.view_search_page();
    const search = localstorageGet('localSearch');
    if (search) {
      setLocalSearch(search.reverse());
    }
    FindTrending();
  }, []);

  /**
   * Removes a recent search item updates the state.
   *
   * @param {string} value - The search text to be removed.
   * @returns {void}
   */
  const onRemoveRecentSearch = (value: string) => {
    const searchLocal = localstorageGet('localSearch') || [];

    const removeRecentSearch = searchLocal?.filter(val => val.text !== value);
    localstorageSet('localSearch', removeRecentSearch);
    setLocalSearch(removeRecentSearch);
  };

  /**
   * in the mobile search page, we show trending search terms to users
   */
  const FindTrending = async () => {
    let trendingSearchResults =
      await apiService.gfTrendingSearchControllerFindAll();
    if (trendingSearchResults) {
      const finalData = trendingSearchResults.map(value => {
        return {
          text: value.term,
          count: value.count,
          id: value.id,
        };
      });

      setTrendingSearch(finalData);
    } else {
      setTrendingSearch(trendingSearchResults);
    }

    let citySearchResults = await apiService.gfStoreTagControllerFindAll();
    console.log(`finalCityData`, citySearchResults);
    if (citySearchResults) {
      const finalCityData = citySearchResults.results.map(value => {
        return {
          text: value.name,
          count: citySearchResults.count,
          id: value.id,
        };
      });

      setCities(finalCityData);
    } else {
      setCities(citySearchResults);
    }
  };

  const clickFavButton = useClickFavMobileFunc(
    analytics.click_fav_button_in_flyer_card,
    true,
  );

  /**
   * Calculates the similarity between two strings using the edit distance algorithm.
   * The similarity is represented as a value between 0 and 1, where 1 indicates identical strings.
   * @param {string} s1 - The first input string.
   * @param {string} s2 - The second input string.
   * @returns {number} - The similarity between the two strings.
   */
  function similarity(s1, s2) {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    const longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (
      (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
    );
  }

  /**
   * Calculates the edit distance between two strings using dynamic programming.
   * The edit distance is the minimum number of single-character edits (insertions, deletions, or substitutions)
   * required to transform one string into the other.
   * @param {string} s1 - The first input string.
   * @param {string} s2 - The second input string.
   * @returns {number} - The edit distance between the two strings.
   */
  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    const costs: any = [];
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j++) {
        if (i === 0) costs[j] = j;
        else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }
  const { clickRemoveFromShoppingListButton } =
    useAddRemoveShoppingListMobileFunc();

  async function clickFavStoreButton(
    e,
    store: GfStoreDto,
    showLikeStoreButton,
  ) {
    ReactGA.event({
      category: '1.2',
      action: `Click Store ${store.merchant.name} fav store Button`,
    });
    e.preventDefault();
    let new_store_fav_list;
    if (showLikeStoreButton) {
      new_store_fav_list =
        await apiService.gfStoreFavouriteListControllerRemoveStore(
          {
            storeId: store.id,
          },
          state.customer.gfStoreFavouriteList.id,
        );
    } else {
      new_store_fav_list =
        await apiService.gfStoreFavouriteListControllerAddStore(
          {
            storeId: store.id,
          },
          state.customer.gfStoreFavouriteList.id,
        );
    }
    const customer = state.customer;
    customer.gfStoreFavouriteList = new_store_fav_list;
    dispatch(setCustomer(customer));
  }
  const PromotionOrFlyerList = state.searchDataList?.map(
    (flyerOrPromotion, key) => {
      if (instanceOfPromotion(flyerOrPromotion)) {
        // let isDealOpen = false;
        const store_in_fav_list = checkIfStoreInFavList(
          flyerOrPromotion.placement.store,
          state.customer.gfStoreFavouriteList,
        );
        const promotion_in_shopping_list = checkIfPromotionInShoppingList(
          flyerOrPromotion,
          state.customer.gfShoppingList,
        );

        return (
          <div
            key={`promotionId=${flyerOrPromotion.id}`}
            data-testid="searchpage-test-id"
          >
            <Link
              data-testid="Search Page promotion Detail Link"
              to={{
                pathname: `/promotiondetail`,
                search: `promotionId=${flyerOrPromotion.id}`,
              }}
            >
              {flyerOrPromotion?.isExclusive ? (
                <ExclusiveDealCard
                  show
                  Logo={
                    flyerOrPromotion.placement.store.merchant.merchantLogo.src
                  }
                  distance={getMyDistanceToStore(
                    flyerOrPromotion.placement.store,
                    state.location,
                  )}
                  name={flyerOrPromotion.placement.store.merchant.name}
                  description={
                    flyerOrPromotion.placement.store.merchant.description
                  }
                  storeFavList={store_in_fav_list}
                  promotionShoppingList={promotion_in_shopping_list}
                  productName={flyerOrPromotion.placement.product.name}
                  RightImage={
                    flyerOrPromotion.placement.product.gfImages?.[0]?.src
                  }
                  price={flyerOrPromotion.placement?.price}
                  discountPrice={flyerOrPromotion.price}
                  onClickShoppingListButton={e => {
                    // isDealOpen = true;
                    clickRemoveFromShoppingListButton(
                      e,
                      flyerOrPromotion,
                      promotion_in_shopping_list !== undefined,
                    );
                  }}
                />
              ) : (
                <BestDealCard
                  validStartDate={flyerOrPromotion.validStartDate}
                  Logo={
                    flyerOrPromotion.placement.store.merchant.merchantLogo.src
                  }
                  distance={getMyDistanceToStore(
                    flyerOrPromotion.placement.store,
                    state.location,
                  )}
                  storeId={flyerOrPromotion.placement.store.id}
                  name={flyerOrPromotion.placement.store.merchant.name}
                  description={
                    flyerOrPromotion.placement.store.merchant.description
                  }
                  storeFavList={store_in_fav_list}
                  promotionShoppingList={promotion_in_shopping_list}
                  productName={flyerOrPromotion.placement.product.name}
                  RightImage={
                    flyerOrPromotion.placement.product.gfImages?.[0]?.src
                  }
                  price={flyerOrPromotion.placement?.price}
                  quantity={flyerOrPromotion.quantity}
                  discountPrice={flyerOrPromotion.price}
                  daysLeft={getDaysLeftOfPromotion(flyerOrPromotion)}
                  onClickFavStoreIcon={e => {
                    clickFavStoreButton(
                      e,
                      flyerOrPromotion.placement.store,
                      store_in_fav_list !== undefined,
                    );
                  }}
                  onClickShoppingListButton={e => {
                    // isDealOpen = true;
                    clickRemoveFromShoppingListButton(
                      e,
                      flyerOrPromotion,
                      promotion_in_shopping_list !== undefined,
                    );
                  }}
                />
              )}
            </Link>
          </div>
        );
      }
      if (instanceOfFlyer(flyerOrPromotion)) {
        let Store = flyerOrPromotion.stores[0];

        if (state.location.coords.latitude) {
          const ArrayList = flyerOrPromotion.stores;
          for (let i = 0; i < ArrayList.length; i++) {
            ArrayList[i].distance = calculateDistance(
              state.location.coords.latitude,
              state.location.coords.longitude,
              ArrayList[i].location.coordinates[1],
              ArrayList[i].location.coordinates[0],
              'K',
            );
          }

          const ResultSort = ArrayList.sort(function (a, b) {
            if (a.distance && b.distance) {
              return a.distance - b.distance;
            } else {
              return -1;
            }
          });
          Store = ResultSort[0];
        }

        const store_in_fav_list = checkIfStoreInFavList(
          Store,
          state.customer.gfStoreFavouriteList,
        );
        return (
          <div
            key={`${key}-${flyerOrPromotion?.id}`}
            data-testid="searchpage-test-id"
          >
            <CardFlyerNew
              data-testid="search page card flyer new "
              startingDate={flyerOrPromotion.validStartDate}
              Logo={Store.merchant?.merchantLogo?.src}
              distance={getMyDistanceToStore(Store, state.location)}
              signature={
                flyerOrPromotion.stores.length === 1 ? Store.signature : ''
              } //flyerOrPromotion.stores.length === 1 is a temp solution. once we optimize the search api endpoint, we can update this(or I think it will automatically work)
              name={Store.merchant.name}
              description={Store.merchant.description}
              storeFavList={store_in_fav_list}
              LeftImage={getSmallImageIfExist(
                flyerOrPromotion.gfImages?.[0],
                state.remoteConfig.getSmallImagesCondition,
              )}
              RightImage={getSmallImageIfExist(
                flyerOrPromotion.gfImages?.[1],
                state.remoteConfig.getSmallImagesCondition,
              )}
              daysLeft={
                flyerOrPromotion?.validStartDate === undefined ||
                flyerOrPromotion?.validStartDate === null
                  ? undefined
                  : getFlyerStatusString(flyerOrPromotion)
              }
              price={flyerOrPromotion.totalSave}
              onClick={e => {
                clickFavButton(e, Store, store_in_fav_list !== undefined);
              }}
              bottomLink={{
                path:
                  flyerOrPromotion?.validStartDate === undefined ||
                  flyerOrPromotion?.validStartDate === null
                    ? `/restaurantview/${flyerOrPromotion.id}`
                    : `/flyerview/${flyerOrPromotion.id}`,
              }}
              topLink={{
                path: `/storedetails`,
                search: `storeId=${Store.id}`,
                storeId: `${Store.id}`,
              }}
            />
          </div>
        );
      }
    },
  );

  const insertItemInto = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
  ];

  async function loadFunc(_page) {
    searchPromotionsAndFlyers(term || '', _page);
  }

  useEffect(() => {
    let searchValue = term || '';
    if (!state.searchDataList?.length) {
      searchPromotionsAndFlyers(searchValue, 1);
      setShowSuggestion(false);
    } else {
      setPageLoader(false);
      setSearchLoader(false);
      setShowSuggestion(false);
    }
    if (window !== undefined) {
      setTimeout(() => {
        window.scrollTo({ top: state.searchScrollPosition });
      }, 5);
    }
  }, [term]);

  const searchText = async (text: string) => {
    if (text === '') {
      history.push(`/search`);
    } else {
      history.push(`/search/${text}`);
    }
    dispatch(setSearchDataNumber(0));
    dispatch(
      setSearchDataList({
        noMerge: true,
        result: [],
      }),
    );
  };

  const searchPromotionsAndFlyers = async (
    text: string,
    _page?: number,
    self?: boolean,
  ) => {
    const currentLanguage = i18n.language;

    if (_page === 1 || _page === undefined || _page === null) {
      setSearchLoader(true);
    }

    analytics.search_item(text);
    setShowSuggestion(false);
    const page = _page ? _page : 1;
    dispatch(setSearchDataNumber(page));
    setHasMore(false);
    if (text !== '') {
      setInputValue(text);

      const flyerAndPromotionResponse =
        await apiService.searchControllerFlyerAndPromotion(
          text,
          currentLanguage !== 'fr' ? ['english & french'] : undefined,
          page,
          5,
        );

      if (
        flyerAndPromotionResponse.results.length === 0 &&
        _page === undefined
      ) {
        dispatch(
          setSearchDataList({
            noMerge: true,
            result: [],
          }),
        );
        setNoSearchFound(true);
        setSearchLoader(false);
        setHasMore(false);
        setInputValue(text);
        const searchLocal = localstorageGet('localSearch');
        const termExists = searchLocal.find(
          trm => trm?.text?.toLowerCase() === text?.toLowerCase(),
        );
        if (termExists) return;
        const removeNullOrUndefined = searchLocal.filter(f => f !== null);
        if (removeNullOrUndefined.length >= 5) {
          removeNullOrUndefined.shift();
          removeNullOrUndefined[4] = { text };
          removeNullOrUndefined.length = 5;
          localstorageSet('localSearch', removeNullOrUndefined);
          setLocalSearch(removeNullOrUndefined.reverse());
          return;
        } else {
          const newArr = [...removeNullOrUndefined, { text }] as any;
          localstorageSet('localSearch', newArr);
          setLocalSearch(newArr.reverse());
        }
      }
      let localSearch = await localstorageGet('localSearch');
      if (flyerAndPromotionResponse?.results?.length) {
        if (localSearch) {
          localSearch = localSearch
            .map(val => {
              if (similarity(val.text, text) >= 0.7) {
              } else {
                return val;
              }
            })
            .filter(val => val !== undefined);
          const foundSame = localSearch.find(val => val.text === text);
          if (localSearch.length <= 8 && !foundSame) {
            localSearch.push({ text });
          } else if (!foundSame) {
            localSearch.shift();
            localSearch.push({ text });
          }
          localstorageSet('localSearch', JSON.stringify(localSearch));
          setInputValue(text);
          setLocalSearch(localSearch.reverse());
          const term: string = localSearch[0].text;
          await apiService.gfTrendingSearchControllerCreate({
            term,
          });
        } else {
          localSearch = [{ text }];
          localstorageSet('localSearch', JSON.stringify(localSearch));
          setLocalSearch(localSearch.reverse());
          const term: string = text;
          await apiService.gfTrendingSearchControllerCreate({
            term,
          });
        }
      } else {
        setNoSearchFound(true);
        setSearchLoader(false);
      }

      if (flyerAndPromotionResponse.results?.length !== 0) {
        let tempData: (GfFlyerDto | GfPromotionDto)[] = [];

        for (
          let index = 0;
          index < flyerAndPromotionResponse.results.length;
          index++
        ) {
          const valueData = flyerAndPromotionResponse.results[index];
          if (instanceOfFlyer(valueData)) {
            const found = tempData
              ?.map((val: any) => val.stores[0].id)
              .indexOf(valueData.stores[0].id);
            if (found > -1) {
              tempData = insertItemInto(tempData, found, valueData);
            } else {
              tempData.push(valueData);
            }
          } else {
            tempData.push(valueData);
          }
        }

        let prev = state.searchDataList || [];
        if (_page === undefined) {
          prev = [];
        }
        dispatch(
          setSearchDataList({
            noMerge: true,
            result: [...prev, ...tempData],
          }),
        );

        if (flyerAndPromotionResponse.results.length > 0) {
          setHasMore(true);
        }

        const flyersList =
          flyerAndPromotionResponse?.results.filter(instanceOfFlyer);
        const promotions =
          flyerAndPromotionResponse?.results.filter(instanceOfPromotion);

        if (flyersList?.length && promotions?.length === 0) {
          const nearByPromotions =
            await apiService.gfStoreControllerGetPromotionsNearAStore(
              flyersList[0].stores[0].id,
            );
          if (nearByPromotions.length) {
            dispatch(
              setSearchDataList({
                noMerge: false,
                result: nearByPromotions,
              }),
            );
          }
        }
      }
    } else if (text === '') {
      dispatch(
        setSearchDataList({
          noMerge: true,
          result: [],
        }),
      );
      setNoSearchFound(false);
      setSearchLoader(false);
      dispatch(setSearchScrollPosition(0));
    }
    setPageLoader(false);
    setSearchLoader(false);
  };
  const spiner = <Spinner></Spinner>;

  return (
    <BoxWrapper data-testid="searchpage-test-id">
      {pageLoader ? (
        <Spinner />
      ) : (
        <Box>
          <SearchInputField
            inputValue={inputValue}
            setInputValue={value => {
              setInputValue(value);
              setShowSuggestion(true);
            }}
            searchCallback={searchText}
          />
          {searchLoader ? (
            <Spinner />
          ) : (
            <Wrapper>
              {PromotionOrFlyerList?.length ? (
                <InfiniteScroll
                  threshold={2000}
                  css={{
                    paddingBottom: '20px',
                  }}
                  pageStart={state.searchDataNumber}
                  loadMore={loadFunc.bind(this)}
                  hasMore={hasMore}
                  loader={
                    <div
                      style={{ textAlign: 'center', paddingBottom: '20px' }}
                      key={0}
                    >
                      Loading ...
                    </div>
                  }
                  data-testid="search wrapper"
                >
                  {PromotionOrFlyerList?.length === 0
                    ? spiner
                    : PromotionOrFlyerList}
                  {/* {PromotionList?.length === 0 ? spiner : PromotionList} */}
                </InfiniteScroll>
              ) : (
                <>
                  {localSearch.length ? (
                    <SearchHistory
                      searchCallback={searchText}
                      BtnData={localSearch}
                      Title={t(messages.Recent_Searches())}
                      canBeEdited={true}
                      onRemoveSearch={onRemoveRecentSearch}
                      dataTestId="Recent Searches"
                    />
                  ) : (
                    <></>
                  )}{' '}
                  {noSearchFound ? (
                    <NoSearchResult
                      message={
                        'There are no results corresponding to your search'
                      }
                    />
                  ) : (
                    <>
                      <SearchHistory
                        dataTestId="Trending Searches"
                        searchCallback={searchText}
                        BtnData={trendingSearch}
                        Title={t(messages.Trending_Searches())}
                      />
                      <SearchHistory
                        dataTestId="City Searches"
                        searchCallback={searchPromotionsAndFlyers}
                        BtnData={cities}
                        Title={t(messages.Trending_Cities())}
                      />
                    </>
                  )}
                </>
              )}
            </Wrapper>
          )}
        </Box>
      )}
      {inputValue?.trim()?.length > 0 && showSuggestion && (
        <SearchSuggest
          inputValue={inputValue}
          searchPromotionsAndFlyers={searchText}
          close={() => {
            analytics.click_close_button_search_box();
            setShowSuggestion(false);
          }}
        />
      )}
    </BoxWrapper>
  );
};

const BoxWrapper = styled.div`
  position: relative;
  margin-top: 70px;
  margin-bottom: 100px;
  padding-bottom: 100px;
`;
const Wrapper = styled.div`
  border-top: 1px solid #7676801f;
  margin-top: 13px;
`;
