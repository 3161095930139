// Path: ./src/app/store/reducer.ts
// reference: https://github.com/hellomuthu23/react-context-example
import { GfCustomerDtoWithLogin, GoFlyerAppState, MapAllStores } from './state';

import {
  ActionType,
  SetCustomer,
  GoFlyerAppActions,
  SetLocation,
  SetExploreFlyerAndPromotionList,
  SetBestDealsFlyerAndPromotionList,
  SetExploreFlyerAndPromotionListNumber,
  SetMapStoreAllStores,
  SetMapStoreCounter,
  SetExploreScrollPosition,
  SetBestDealsScrollPosition,
  SetMenuSideBar,
  SetFlyerTagListPosition,
  SetFlyerTagListNumber,
  SetFlyerTagList,
  SetRemoteConfig,
  SetBestDealListNumber,
  SetShowBanner,
  SetLatestFlyer,
  SetLatestFlyerScrollPosition,
  SetLatestFlyerNumber,
  SetUpComingFlyerScrollPosition,
  SetUpComingFlyer,
  SetUpComingFlyerNumber,
  SetFavoriteFlyerList,
  SetFavoriteFlyerPosition,
  latestFlyerPayload,
  SetExploreFlyerAndPromotionListPayload,
  UpComingFlyerPayload,
  SetSearchScrollPosition,
  SetSearchDataNumber,
  SetSearchDataListPayload,
  SetSearchDataList,
} from './actions';
import {
  GfFlyerDto,
  GfPromotionDto,
  GfStoreDto,
} from '@swagger/typescript-fetch-goflyer';
import { Position } from '@capacitor/geolocation';
import { localstorageSet } from 'utils/localstorage';

export function goflyerAppReducer(
  state: GoFlyerAppState,
  action: GoFlyerAppActions,
): GoFlyerAppState {
  switch (action.type) {
    case ActionType.SetCustomer:
      return { ...state, customer: action.payload };
    case ActionType.setShowBanner:
      return { ...state, showBanner: action.payload };
    case ActionType.SetLocation:
      return { ...state, location: action.payload };
    case ActionType.SetExploreFlyerAndPromotionList:
      const merged: (GfFlyerDto | GfPromotionDto)[] = [];

      if (action.payload.noMerge) {
        merged.push(
          ...(action.payload.result as (GfFlyerDto | GfPromotionDto)[]),
        );
      } else {
        const preValue = state.exploreFlyerAndPromotionList as (
          | GfFlyerDto
          | GfPromotionDto
        )[];
        merged.push(...preValue);
        const filteredArray2 = action.payload.result?.filter(
          obj2 => !preValue.some(obj1 => obj1.id === obj2.id),
        );
        merged.push(...(filteredArray2 as (GfFlyerDto | GfPromotionDto)[]));
      }
      return { ...state, exploreFlyerAndPromotionList: merged };
    case ActionType.SetBestDealsFlyerAndPromotionList:
      const arrayPromotion: GfPromotionDto[] = [];
      arrayPromotion.push(
        ...(state.bestDealsFlyerAndPromotionList as GfPromotionDto[]),
      );
      arrayPromotion.push(...(action.payload as GfPromotionDto[]));
      const idsPromotion = arrayPromotion.map(o => o.id);
      const resultPromotion: GfPromotionDto[] = arrayPromotion.filter(
        ({ id }, index) => !idsPromotion.includes(id, index + 1),
      );
      return { ...state, bestDealsFlyerAndPromotionList: resultPromotion };
    case ActionType.SetExploreFlyerAndPromotionListNumber:
      return { ...state, exploreFlyerAndPromotionListNumber: action.payload };
    case ActionType.SetBestDealListNumber:
      return { ...state, bestDealListNumber: action.payload };
    case ActionType.SetMapStoreAllStores:
      const arrayAllStores: MapAllStores[] = [];
      arrayAllStores.push(...(state.mapStoreAllStores as MapAllStores[]));
      arrayAllStores.push(...(action.payload as MapAllStores[]));
      const idsAllStores = arrayAllStores.map(o => o.id);
      const resultAllStores: MapAllStores[] = arrayAllStores.filter(
        ({ id }, index) => !idsAllStores.includes(id, index + 1),
      );
      return { ...state, mapStoreAllStores: resultAllStores };
    case ActionType.SetMapStoreCounter:
      return { ...state, mapStoreCounter: action.payload };
    case ActionType.SetExploreScrollPosition:
      return { ...state, exploreScrollPosition: action.payload };
    case ActionType.SetBestDealsScrollPosition:
      return { ...state, bestDealsScrollPosition: action.payload };

    case ActionType.SetMenuSideBar:
      return { ...state, menuSideBar: action.payload };
    case ActionType.SetFlyerTagList:
      const { type, flyer, noMerge } = action.payload;
      const FinalResult: GfFlyerDto[] = [];
      if (noMerge) {
        FinalResult.push(...(flyer as GfFlyerDto[]));
      } else {
        const preValueTag = state.flyerTagList[`${type}`]
          ?.flyer as GfFlyerDto[];
        FinalResult.push(...preValueTag);
        const tagFlyerResult = flyer.filter(
          item2 =>
            !state.flyerTagList[`${type}`]?.flyer.some(
              item1 => item1.id === item2.id,
            ),
        );
        FinalResult.push(...tagFlyerResult);
      }
      return {
        ...state,
        flyerTagList: {
          ...state.flyerTagList,
          [`${type}`]: {
            ...state.flyerTagList[`${type}`],
            flyer: FinalResult,
          },
        },
      };
    case ActionType.SetFlyerTagListNumber:
      const { type: numberType, number } = action.payload;
      return {
        ...state,
        flyerTagList: {
          ...state.flyerTagList,
          [`${numberType}`]: {
            ...state.flyerTagList[`${numberType}`],
            number,
          },
        },
      };
    case ActionType.SetFlyerTagListPosition:
      const { type: positionType, position } = action.payload;
      return {
        ...state,
        flyerTagList: {
          ...state.flyerTagList,
          [`${positionType}`]: {
            ...state.flyerTagList[`${positionType}`],
            position,
          },
        },
      };
    case ActionType.SetRemoteConfig:
      return {
        ...state,
        ...action.payload,
      };
    case ActionType.SetLatestFlyer:
      const latestMerged: GfFlyerDto[] = [];

      if (action.payload.noMerge) {
        latestMerged.push(...(action.payload.result as GfFlyerDto[]));
      } else {
        const preValue = state.latestFlyer as GfFlyerDto[];
        latestMerged.push(...preValue);
        const latestFiltered = action.payload.result?.filter(
          obj2 => !preValue.some(obj1 => obj1.id === obj2.id),
        );
        latestMerged.push(...(latestFiltered as GfFlyerDto[]));
      }
      return { ...state, latestFlyer: latestMerged };
    case ActionType.SetLatestFlyerNumber:
      return { ...state, latestFlyerNumber: action.payload };

    case ActionType.SetLatestFlyerScrollPosition:
      return { ...state, latestFlyerScrollPosition: action.payload };

    case ActionType.SetUpComingFlyer:
      const upComingArray: GfFlyerDto[] = [];

      if (action.payload.noMerge) {
        upComingArray.push(...(action.payload.result as GfFlyerDto[]));
      } else {
        const preValue = state.upComingFlyer as GfFlyerDto[];
        upComingArray.push(...preValue);
        const upComingFiltered = action.payload.result?.filter(
          obj2 => !preValue.some(obj1 => obj1.id === obj2.id),
        );
        upComingArray.push(...(upComingFiltered as GfFlyerDto[]));
      }

      return { ...state, upComingFlyer: upComingArray };
    case ActionType.SetUpComingFlyerNumber:
      return { ...state, upComingFlyerNumber: action.payload };

    case ActionType.SetUpComingFlyerScrollPosition:
      return { ...state, upComingFlyerScrollPosition: action.payload };

    case ActionType.SetFavoriteFlyerList:
      return {
        ...state,
        favoriteFlyer: { ...state.favoriteFlyer, flyerList: action.payload },
      };

    case ActionType.SetFavoriteFlyerPosition:
      return {
        ...state,
        favoriteFlyer: { ...state.favoriteFlyer, position: action.payload },
      };
    case ActionType.SetSearchScrollPosition:
      return {
        ...state,
        searchScrollPosition: action.payload,
      };
    case ActionType.SetSearchDataNumber:
      return { ...state, searchDataNumber: action.payload };
    case ActionType.SetSearchDataList:
      const mergedSearch: (GfFlyerDto | GfPromotionDto)[] = [];

      if (action.payload.noMerge) {
        mergedSearch.push(
          ...(action.payload.result as (GfFlyerDto | GfPromotionDto)[]),
        );
      } else {
        const preValue = state.searchDataList as (
          | GfFlyerDto
          | GfPromotionDto
        )[];
        mergedSearch.push(...preValue);
        const filteredArray2 = action.payload.result?.filter(
          obj2 => !preValue.some(obj1 => obj1.id === obj2.id),
        );
        mergedSearch.push(
          ...(filteredArray2 as (GfFlyerDto | GfPromotionDto)[]),
        );
      }
      return { ...state, searchDataList: mergedSearch };
    // case ActionType.SetPromotionFavouriteList:
    //   return { ...state, promotionFavouriteList: action.payload };
    default:
      return state;
  }
}

// helper functions to simplify the caller
export const setCustomer = (customer: GfCustomerDtoWithLogin): SetCustomer => {
  localstorageSet('customer', customer);
  return {
    type: ActionType.SetCustomer,
    payload: customer,
  };
};

export const setLocation = (location: Position): SetLocation => ({
  type: ActionType.SetLocation,
  payload: location,
});
export const setExploreFlyerAndPromotionList = (
  flyerListPage: SetExploreFlyerAndPromotionListPayload,
): SetExploreFlyerAndPromotionList => {
  return {
    type: ActionType.SetExploreFlyerAndPromotionList,
    payload: flyerListPage,
  };
};

export const setBestDealsFlyerAndPromotionList = (
  PromotionListPage: GfPromotionDto[] | undefined,
): SetBestDealsFlyerAndPromotionList => {
  return {
    type: ActionType.SetBestDealsFlyerAndPromotionList,
    payload: PromotionListPage,
  };
};
export const setExploreFlyerAndPromotionListNumber = (
  flyerListPageNumber: number,
): SetExploreFlyerAndPromotionListNumber => {
  return {
    type: ActionType.SetExploreFlyerAndPromotionListNumber,
    payload: flyerListPageNumber,
  };
};

export const setBestDealListNumber = (
  dealsListPageNumber: number,
): SetBestDealListNumber => {
  return {
    type: ActionType.SetBestDealListNumber,
    payload: dealsListPageNumber,
  };
};

export const setMapStoreAllStores = (
  AllStores: MapAllStores[] | undefined,
): SetMapStoreAllStores => {
  return {
    type: ActionType.SetMapStoreAllStores,
    payload: AllStores,
  };
};
export const setMapStoreCounter = (Counter: number): SetMapStoreCounter => {
  return {
    type: ActionType.SetMapStoreCounter,
    payload: Counter,
  };
};
export const setExploreScrollPosition = (
  ExploreScrollPosition: number,
): SetExploreScrollPosition => {
  return {
    type: ActionType.SetExploreScrollPosition,
    payload: ExploreScrollPosition,
  };
};

export const setBestDealsScrollPosition = (
  BestDealsScrollPosition: number,
): SetBestDealsScrollPosition => {
  return {
    type: ActionType.SetBestDealsScrollPosition,
    payload: BestDealsScrollPosition,
  };
};
// start here
interface FlyerTagList {
  type: string;
  flyer: GfFlyerDto[];
  noMerge: boolean;
}
export const setFlyerTagList = (
  SetFlyerTagList: FlyerTagList,
): SetFlyerTagList => {
  return {
    type: ActionType.SetFlyerTagList,
    payload: SetFlyerTagList,
  };
};

interface FlyerTaglistNumber {
  type: string;
  number: number;
}
export const setFlyerTagListNumber = (
  SetFlyerTagListNumber: FlyerTaglistNumber,
): SetFlyerTagListNumber => {
  return {
    type: ActionType.SetFlyerTagListNumber,
    payload: SetFlyerTagListNumber,
  };
};

interface FlyerTaglistPosition {
  type: string;
  position: number;
}
export const setFlyerTagListPosition = (
  SetFlyerTagListPosition: FlyerTaglistPosition,
): SetFlyerTagListPosition => {
  return {
    type: ActionType.SetFlyerTagListPosition,
    payload: SetFlyerTagListPosition,
  };
};

export const setMenuSideBar = (menuSideBarValue: boolean): SetMenuSideBar => {
  return {
    type: ActionType.SetMenuSideBar,
    payload: menuSideBarValue,
  };
};

// setShowBanner
export const setShowBanner = (showBanner: boolean): SetShowBanner => {
  return {
    type: ActionType.setShowBanner,
    payload: showBanner,
  };
};

export const setRemoteConfig = (remoteConfig: {
  remoteConfig: {
    getSmallImagesCondition: boolean;
  };
}): SetRemoteConfig => {
  return {
    type: ActionType.SetRemoteConfig,
    payload: remoteConfig,
  };
};

export const setLatestFlyer = (
  flyerLatestPage: latestFlyerPayload,
): SetLatestFlyer => {
  return {
    type: ActionType.SetLatestFlyer,

    payload: flyerLatestPage,
  };
};
export const setLatestFlyerPosition = (
  flyerListPageNumber: number,
): SetLatestFlyerScrollPosition => {
  return {
    type: ActionType.SetLatestFlyerScrollPosition,

    payload: flyerListPageNumber,
  };
};

export const setLatestFlyerNumber = (
  flyerListPageNumber: number,
): SetLatestFlyerNumber => {
  return {
    type: ActionType.SetLatestFlyerNumber,

    payload: flyerListPageNumber,
  };
};

export const setUpComingFlyer = (
  flyerUpComingPage: UpComingFlyerPayload,
): SetUpComingFlyer => {
  return {
    type: ActionType.SetUpComingFlyer,
    payload: flyerUpComingPage,
  };
};
export const setUpComingFlyerPosition = (
  flyerUpComingFlyerPosition: number,
): SetUpComingFlyerScrollPosition => {
  return {
    type: ActionType.SetUpComingFlyerScrollPosition,

    payload: flyerUpComingFlyerPosition,
  };
};

export const setUpComingFlyerNumber = (
  flyerUpComingPageNumber: number,
): SetUpComingFlyerNumber => {
  return {
    type: ActionType.SetUpComingFlyerNumber,
    payload: flyerUpComingPageNumber,
  };
};

export const setFavoriteFlyerList = (
  flyerFavoriteList: GfStoreDto[],
): SetFavoriteFlyerList => {
  return {
    type: ActionType.SetFavoriteFlyerList,
    payload: flyerFavoriteList,
  };
};

export const setFavoriteFlyerPosition = (
  favoriteFlyerPosition: number,
): SetFavoriteFlyerPosition => {
  return {
    type: ActionType.SetFavoriteFlyerPosition,
    payload: favoriteFlyerPosition,
  };
};

export const setSearchScrollPosition = (
  searchScrollPosition: number,
): SetSearchScrollPosition => {
  return {
    type: ActionType.SetSearchScrollPosition,
    payload: searchScrollPosition,
  };
};
export const setSearchDataList = (
  searchList: SetSearchDataListPayload,
): SetSearchDataList => {
  return {
    type: ActionType.SetSearchDataList,
    payload: searchList,
  };
};

export const setSearchDataNumber = (number: number): SetSearchDataNumber => {
  return {
    type: ActionType.SetSearchDataNumber,
    payload: number,
  };
};

// export const setPromotionFavouriteList = (
//   promotionFavouriteList: GfPromotionFavouriteListDto,
// ): SetPromotionFavouriteList => ({
//   type: ActionType.SetPromotionFavouriteList,
//   payload: promotionFavouriteList,
// });

// export const setStoreFavouriteList = (
//   storeFavouriteList: GfStoreFavouriteListDto,
// ): SetStoreFavouriteList => ({
//   type: ActionType.SetStoreFavouriteList,
//   payload: storeFavouriteList,
// });
